.main-footer {
  background-color: $white;
  background-image: url('img/img-fons-peu.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  p.site {
    text-transform: uppercase;
    font-size: rem-calc(16);
    font-weight: 300;
    letter-spacing: 300;
    margin-top: rem-calc(10);
  }
  .site-menu {
    list-style: none;
    margin-top: rem-calc(30);
    li {
      display: inline-block;
      margin-left: rem-calc(10);
      &:after {
        content: '-';
        margin-left: rem-calc(10);
      }
      &.last {
        &:after {
          content: none;
        }
      }
      a {
        color: #000;
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 1.3px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social {
    list-style: none;
    margin-bottom: rem-calc(50);
    li {
      display: inline-block;
      margin: rem-calc(0 8);
      a {
        &:hover {
          opacity: .6;
        }
      }
    }
  }
  .copy {
    margin-bottom: rem-calc(50);
    font-size: rem-calc(14);
    letter-spacing: .9px;
    font-weight: 700;
  }
}
