.slider__overlay {
	background-color: rgba(#fff,.85);
	position: absolute;
	left: 0;
	top: 0;
	width: 50%;
	height: 100%;
	&--right {
		left: auto;
		right: 0;
	}

}
.slider.slick-dotted.slick-slider {
    margin-bottom: 0;
}
.slider {
	text-align: center;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	position:relative;
	background-color: #555;
	height: rem-calc(583);
	margin-top: rem-calc(20);
	@media #{$large-up} {
		height: rem-calc(783);
		margin-top: rem-calc(50);
	}
    div:not(:first-child) {
        display:none;
    }
    &.slick-initialized {
        div {
            display:block;
        }
    }

	// contenidor imatge
	.slick-slide {
		position: relative;
		background-color: #555;
		background-size: cover;
		background-position: 50% 0;
		height: rem-calc(583);
		> div > div {
			height: rem-calc(583);
		}
		@media #{$large-up} {
			height: rem-calc(783);
			> div > div {
				height: rem-calc(783);
			}
		}
	}

	// caption
	&__caption {
		text-align: center;
		position: absolute;
		padding: 0 vr(1.75);
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		> *:last-child {
			margin-bottom: 0;
		}
		width: 60%;
		&--left,
		&--right {
			padding: 0;
			width: 60%;
			transform: translate(0,-50%);


		}
		&--left {
			text-align: left;
			left: vr(4);
		}
		&--right {
			left: auto;
			text-align: right;
			right: vr(4);
		}
		h2 {
			color: $body-font-color;
			font-weight: 300;
			text-align: left;
			font-size:rem-calc(46);
			letter-spacing: 0;
			line-height: rem-calc(50);
			text-transform: none;
			margin: 0;
			@media #{$large-up} {
				font-size:rem-calc(56);
				line-height: rem-calc(60);
			}
			a{
				color: $body-font-color;
			}
		}
		p {
			margin-bottom: vr(.5);
			color: $body-font-color;
			text-shadow: 0 2px 3px rgba(0,0,0,0.4);
			a {
				color: #fff;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.panel {
			color: $body-font-color;
			background-color: #fff;
			padding: vr(1);
			p {
				color: $body-font-color;
				text-shadow:none;
			}
			a {

			}
			.btn {
				background-color: $primary-color;
				color: #fff;
				// border: 2px solid $primary-color;
				margin-bottom: 0;
				&:hover {
					// background-color: #fff;
					// color: $primary-color;
					// border-bottom-width: 2px;
					text-decoration: none;
					outline: none;
				}
			}
		}
	}
}


// fletxes
.slick-prev,
.slick-next {
	margin-top: 0;
	width: 21px;
	height: 25px;
	&:before {
		content: '';
		display: block;
		font-size: 1rem;
	}
	&:hover,
	&:focus {
		&:before {
			opacity: .8;
		}
	}
}
.slick-prev {
    left: vr(1);
    z-index: 1;
    .js-menu-open & {
        z-index: 0;
    }
    &:before {

    }
}
.slick-next {
	right: vr(1);
	&:before {

	}
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25 !important;
}


// botons navegació
.slider .slick-dots {
	bottom: 0;
	left: 0;
	//transform: translateX(-50%);
	background-color: #000;
	background-color: rgba($primary-color,.9);
	display: block;
	margin: 0;
	padding:vr(1) vr(.5) vr(.5);
	@media #{$large-up} {
		padding: vr(1.5);
	}
	li {
		vertical-align: middle;
		margin-left: vr(1.5);
		margin-right: vr(1.5);
		width: vr(1);
		height: vr(1);
		&:before {
			display: none;
		}
		button {
			padding: 0;
			width: vr(1);
			height: vr(1);

			&:before {
				width: vr(1);
				height: vr(1);
				left: 0;
				top: 0;
				font-size: rem-calc(21);
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					/* IE10+ CSS styles go here */
					top: 11px;
				}
				.ie9 & {
					top: 11px;
				}
			}
		}
		&.slick-active button:before {
			color: $secondary-color;
		}
	}
}
