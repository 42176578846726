.logo{
  display: inline;
  max-width: 200px;
  float: left;
  padding-left: .9375rem;
  padding-right: .9375rem;
  @media only screen and (min-width: 950px){
    max-width: 255px;
  }
}
.menu{
  position: relative;
}
.main-nav {
  // nav mobile
  margin-bottom: $paragraph-margin-bottom;

  padding: 0;
  transition: all 0.3s ease-out;
  //position: relative;
  @media #{$medium-up} {
    padding: rem-calc(27 23);
    background: $primary-color;
    float: right;
    margin-top: 2.5rem;
    position: relative;
    bottom: -0.6rem;
  }
  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(16);
    margin: 0;
    text-transform: uppercase;

    a {
      color: $white;
      display: block;
      padding: 0.5em 0;
    }
  }

  .js & {
    .menu-wrapper{
      display: none;
    }
  }
  .js-menu-open & {
    .menu-wrapper{
      display: block;
    }
  }
  @media #{$medium} {
    .menu-wrapper{
      display: block!important;
    }
  }
  .menu-button {
      background-color: transparent;
      border-radius: 50%;
      height: rem-calc(50);
      width: rem-calc(50);
      position: absolute;
      right: 20px;
      top: -28px;
      border: 1px solid #fff;
      display: block;
      &:focus{
        outline: none;
      }

      @media #{$medium-up} {
        display: none;
      }
    }
    .hamburger-box {
      top: 3px;
    }
    .hamburger-inner {
      width: 27px;
      height: 1px;
      margin-left: -2px;
      margin-top: -3px;
      &,
      &::before,
      &::after {
        width: 27px;
        height: 1px;
      }
    }
    .menu-wrapper {
      background-color: $primary-color;
      margin-left: 0px;
      margin-right: 0px;
      padding: rem-calc(32 25 0);
      text-align: center;
      position: absolute;
      top: 35px;
      width: 100%;
      z-index: 20;
      min-height: 100vh;

      @media #{$medium-up} {
        min-height: 25px!important;
        width: 100%;
        position: relative;
        background-color: transparent;
        margin: 0;
        padding: 0;
        top: 0px;
      }
    }
    .site-menu {
      list-style: none;
      @media #{$medium-up} {
        text-align: right;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      li {
        padding: vr(.5) 0;
        display: block;
        &:last-child {
          padding-bottom: 0;
        }
        @media only screen and (min-width: 768px){
          display: inline-block;
          padding: 0 vr(.3);
        }
        @media only screen and (min-width: 900px){
          display: inline-block;
          padding: 0 vr(.6);
          &:last-child {
            padding-right: vr(.3);
          }
        }
        @media #{$large-up} {
          padding: 0 vr(1);
        }
      }
      a {
        color: #fff;
        font-size: rem-calc(18);
        @media only screen and (min-width: 768px){
          font-size: rem-calc(12);
        }
        @media only screen and (min-width: 900px){
          font-size: rem-calc(14);
          padding-bottom: 1.3rem;
          display: block;
          position: relative;
          text-decoration: none;
          &:before {
            content: "";
            height: 3px;
            width: 1px;
            display: block;
            background-color: transparent;
            position: absolute;
            left: 0;
            bottom: 12px;
            transition: background-color 0.1s ease-in, width 0.1s ease-in;
          }
          &:hover {
            text-decoration: none;
          }
          &:hover,
          &[aria-current="page"] {
            text-decoration: none;
            &:before {
              background-color: #fff;
              width: 100%;
            }
            &[aria-haspopup="true"] {
              &:before {
                width: calc(100% - 12.8px);
              }
            }
          }
        }
      }
    }
    .mobile-tools {
      background-color: #9fd6f0;
      padding: vr(1) 0;
      //margin-left: -$mobile-padding;
      //margin-right: -$mobile-padding;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      .btn {
        margin-bottom: 0;
      }
    }
  // nav medium
  @media #{$medium-up} {
    display: block !important;
    padding: 0;
    position: static !important;
    width: auto !important;
    margin-top: -10px !important;
    float: right;
    padding-top: 45px;
    ul {
      position: relative;
      float: none;
      li {
        display: inline-block;
        position: relative;
        padding-left: 0.75em;
        padding-right: 0.75em;
        > a {
          color: #fff;
          padding-top: 0.75em;
          padding-bottom: 0.75em;
          font-size: rem-calc(14);
          font-weight: 700;
          letter-spacing: 1px;
          &:hover,
          &.is-hovered {
            text-decoration: none;
            color: $white;
            //border-bottom: solid 3px #ffffff;
            &:after {
              //border-top-color: $secondary-color !important;
            }
          }
        }

        .home &.inici {
          display: none;
        }

        &.active {
          > a {
            background: $primary-color;
            color: $white;
          }
        }

        &.has-submenu {
          > a {
            &:after {
              @include triangle(4px, $primary-color, top);
              display: inline-block;
              margin-left: 10px;
            }
          }
        }

        &.has-submenu:hover a + .submenu {
          left: 0;
        }
      }
    }

  }
}


.menu-lang--desktop {
  display: none;
  @media #{$medium-up} {
    display: inline-block;

    /*float: right;


    margin-right: 0;
    margin-top: 0;*/
    position: absolute;
    top: 0;
    right: 0;
  }
}
/*body.js-menu-open{
   overflow: hidden;
}*/
