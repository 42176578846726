.top {

  text-align: center;
  text-transform: uppercase;
  color: $base;
  display: block;
  margin-bottom: .5em;
  @media #{$medium-up} {
    position: fixed;
    right: 30px;
    bottom: 30px;
    margin-bottom: 0;
    padding-top: 1.5em;
  }
  &:before {
    content: '';
    @extend .top;
    display: block;
  }

  &:hover {
    border: 0;
    color: $tuatara;
  }
}
