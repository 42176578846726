.interior {
  .main-content {
    h1 {
    font-weight: 300;
    font-size: rem-calc(16);
    letter-spacing: 1.3px;
    text-align: center;
    text-transform: uppercase;
    margin-top: rem-calc(50);
    margin-bottom: 0;

  }
  h2 {
    margin-top: rem-calc(42);
    &.heading{
      margin-top: rem-calc(12);
    }
  }
  .bg {
    padding-top: 0;
  }
  .testimonis {
    margin-top: rem-calc(30);
    .btn {
      margin-top: rem-calc(30);
    }

  }
  .text-centered {
    text-align: center;
  }
  .testimoni {
    p {
      font-size: rem-calc(20);
      font-weight: 400;
      font-style: italic;
      text-align: left;
      line-height: normal;
      @media #{$medium-up} {
        font-size: rem-calc(25);
      }
    }
    h3 {
      font-weight: 700;
      font-size: rem-calc(16);
      text-align: left;
    }
    h4 {
      color: $primary-color;
      font-weight: 700;
      font-size: rem-calc(16);
      text-align: left;
    }
    .testimoni-text {
      padding: rem-calc(50);
      @media #{$large-up} {
        background-color: #fff;
        text-align: left;
      }
    }
    .testimoni-imatge {
      padding: 0;
    }
  }
  .sabermes {
    text-align: center;
    img{
      max-width:300px;
      &.agencia-de-residus-de-catalunya{
        width:207px;
      }
      &.area-metropolitana-de-barcelona{
        width:300px;
      }
      &.prou-malbaratar-aliments{
        width:277px;
      }
      &.som-gent-de-profit{
        width:240px;
      }
      &.plataforma-aprofitem-els-aliments{
        width:200px;
      }
      &.espai-ambiental{
        width:200px;
      }
      &.junts-contra-el-malbaratament-alimentari-aecoc{
        width:200px;
      }
    }
  }
  .inline-list {
    text-align: center;
    margin: 0 auto;
    margin-top: rem-calc(40);
    margin-left: 0 !important;
    margin-bottom: rem-calc(60);
    li {
      display: inline-block;
      float: none;
      margin: rem-calc(20 15 40);
      padding: 0;
      @media #{$medium-up} {
        padding: rem-calc(0 20);
      }
      &:before {
        content: none;
      }
      a {
        display: inline-block;
        text-decoration: none;
        color: #000;
        span {
          display: block;
          margin-top: rem-calc(15);
        }
        &:hover {
          opacity: .5;
        }
      }
    }
  }
}
.testimonis {
  text-align: center;
}
.frasedestacada, .frasedestacada_bg {
  position: relative;
  padding: rem-calc(40 0) !important;
  text-align: center;
  p {
    color: $white;
    font-style: italic;
    font-weight: 300;
    font-size: rem-calc(23);
    @media #{$large-up} {
      font-size: rem-calc(28);
    }
  }
  &:before {
    content: '';
    @media #{$large-up} {
      background-image: url('img/ico-quote.svg');
      width: rem-calc(179);
      height: rem-calc(129);
      position: absolute;
      top: 90px;
      left: 200px;
    }
  }
}
.frasedestacada_bg {
  p {
    color: #000;
  }
  &:before {
    content: '';
    @media #{$large-up} {
      background-image: url('img/ico-quote-clar.svg');
      width: rem-calc(137);
      height: rem-calc(99);
      top: 50px;
    }
  }
}
.passes {
  text-align: center;
  padding: rem-calc(50 0 70);
  h2 {
    color: #000;
  }
  .primeres {
    text-align: center;
    margin: 0 auto;
    li {
      border: solid 2px $primary-color;
      border-radius: 8px;
      padding: rem-calc(20);
      margin-bottom: rem-calc(20);
      @media #{$medium-up} {
        margin: rem-calc(30 0);
        height: rem-calc(250);
        margin-right: 1.65%;
        margin-left: 1.65%;
        max-width: 30%;
        &:nth-child(3n){
          margin-right:0;
        }
      }
      @media #{$large-up} {
        //margin: rem-calc(30 8 0);
        //max-width: rem-calc(235);
        height: rem-calc(275);
        margin-right: 1%;
        margin-left: 1%;
        max-width: 23%;
        &:nth-child(3n){
          margin-right:1%;
        }
        &:nth-child(4n){
          margin-right:0;
        }
      }

      &:before {
        content: none;
      }
      p {
        font-weight: 300;
        font-size: rem-calc(18);
        line-height: 28px;
        margin-top: rem-calc(10);
        @media only screen and (min-width: 923px){
          font-size: rem-calc(23);
        }
      }
    }
  }
}
.entitats-benef {
  h2 {
    margin: 0;
    text-align: left;
    font-size: rem-calc(25);
    margin-bottom: rem-calc(20);
    @media #{$large-up} {
      font-size: rem-calc(35);
    }
  }
  .provincia {
    margin-bottom: rem-calc(50);
  }
  .llistat-entitats {
    ul {
      @media #{$medium-up} {
        border-left: 1px solid #000;
        padding-left: rem-calc(30);
        column-count: 2;
      }
      li {
        font-size: rem-calc(16);
        line-height: 1.3;
        padding-bottom: rem-calc(12);
        &:before {
          content: none;
        }
      }
    }
  }
}
  .grafic {
    position: relative;
    max-width: 995px;
    padding: 0 1em;
    @media #{$medium-up} {
      padding: 0 2em;
    }
    @media only screen and (min-width: 1200px){
      max-width: 1200px;
    }
    .quadres {
      border: solid 3px #ffffff;
      border-radius: 8px;
      position: relative;
      margin: rem-calc(30) 0;
      padding: 3em 1.5em 1.5em;

      @media only screen and (min-width: 1024px){
        padding: 1.5em;
        margin:0;
      }
      span {
        font-size: rem-calc(40);
        color: $primary-color;
        background-color: $white;
        border-radius: 50px;

        position: absolute;
        width: 59px;
        height: 59px;
        line-height: 59px;
        top: -35px;
        left: -30px;
        text-align: center;
        @media #{$medium-up} {
          left: -35px;
          font-size: rem-calc(50);
           width: 79px;
          height: 79px;
          line-height: 79px;
        }
      }
      @media only screen and (min-width: 1024px){
        width: rem-calc(350);
        padding-left: 3em;
        position: absolute;
        &.primer{
          top: 50px;
          left: 100px;
          &:after{
            position: absolute;
            content: '';
            right:-380px;
            top: 50%;
            width: 380px;
            background-color: #fff;
            height: 3px;
          }
        }
        &.segon{
          top: 350px;
          right: 0;
          &:after{
            position: absolute;
            content: '';
            left:170px;
            top: -130px;
            width: 3px;
            background-color: #fff;
            height: 130px;
          }
        }
        &.tercer{
          top: 400px;
          left: 30px;
          &:after{
            position: absolute;
            content: '';
            right:-270px;
            top: 50%;
            width: 270px;
            background-color: #fff;
            height: 3px;
          }
          &:before{
            position: absolute;
            content: '';
            left:168px;
            bottom: -130px;
            width: 3px;
            background-color: #fff;
            height: 130px;
          }
        }
        &.quart{
          top: 720px;
          left: 50%;
          margin-left:-175px;
          &:before{
            position: absolute;
            content: '';
            left:-115px;
            top: 50%;
            width: 115px;
            background-color: #fff;
            height: 3px;
          }
          &:after{
            position: absolute;
            content: '';
            right:-125px;
            top: 50%;
            width: 125px;
            background-color: #fff;
            height: 3px;
          }
        }
      }
      @media only screen and (min-width: 1200px){
        width: rem-calc(400);
        &.primer{
          &:after{
            right:-280px;
            width: 280px;
          }
        }
        &.segon{
          right: -20px;
          top: 300px;
          &:after{
            left:82px;
            top: -155px;
            height: 155px;
          }
        }
        &.tercer{
          top: 400px;
          left: -20px;
          &:before{
            bottom:-160px;
            height: 160px;
          }
          &:after{
            right:-315px;
            width: 315px;
          }

        }
        &.quart{
          margin-left:-200px;
          &:before{
            left:-155px;
            width: 155px;
          }

        }
      }
      p{
        font-size: rem-calc(18);
        line-height: 1.3;
      }
      ol{
        margin-bottom: 0;
        margin-left: 1rem;
        li{
          padding-left: .5em;
          line-height: 1.2;
          padding-bottom: .5em;
          &:last-child{
            padding-bottom: 0;
          }
        }
      }
    }
    @media only screen and (min-width: 1024px){
      min-height: 1050px;
    }
    @media only screen and (min-width: 1200px){
      min-height: 990px;
    }
  }
  .imatge{
    background-color: #f4f1e6;
    border-radius:100%;
    border: solid 3px #fff;
    text-align: center;
    line-height: 120px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: relative;
    &:before{
      position: absolute;
      content: '';
      top:-35px;
      width: 3px;
      left:50%;
      background-color: #fff;
      height: 35px;
    }
    &:after{
      position: absolute;
      content: '';
      bottom:-35px;
      width: 3px;
      left:50%;
      background-color: #fff;
      height: 35px;
    }
    &.quart:after{
        content: none;
    }
    @media only screen and (min-width: 1024px){
      position: absolute;
      z-index: 2;
      &:before{
        content: none;
      }
      &:after{
        content: none;
      }
      &.primer{
        top: 115px;
        left: 760px;
      }
      &.segon{
        top: 465px;
        left: 450px;
      }
      &.tercer{
        top: 760px;
        left: 145px;
      }
      &.quart{
        top: 760px;
        right: 100px;
      }
    }
    @media only screen and (min-width: 1200px){
      &.primer{
        top: 85px;
        left: 580px;
      }
      &.segon{
        left: 470px;
      }
      &.tercer{
        top: 750px;
        left: 94px;
      }
      &.quart{
        top: 750px;
        right: 100px;
      }
    }
  }
}
.basica {
  h3 {
    text-transform: uppercase;
  }
  h4 {
    color: $primary-color;
  }
}
.quefem{
  .final{
    a{
      max-width: 250px;
    }
  }
}
