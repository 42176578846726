.home {
  .rsc {
    text-align: center;

    h3 {
      font-size: rem-calc(50);
      font-weight: 900;
      color: $primary-color;
      margin-top: rem-calc(40);
      margin-bottom: 0;
    }
    h4 {
      font-weight: 700;
      font-size: rem-calc(16);
      font-size: rem-calc(16);
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: rem-calc(10);
    }
    .grafic-rsc {
      margin: 0 auto;
      position: relative;
      margin-top: rem-calc(10);
      padding-top: 41px;
      margin-bottom:rem-calc(30);
      width:330px;
      @media only screen and (max-width: 358px){
        margin-left: -28px;
      }
      &:before {
        content: '';
        background-image: url('img/grafic-rsc-1-mob.svg');
        background-repeat: no-repeat;
        background-position: center top;
        display: block;
        height: 57px;
        width: 252px;
        background-size: 252px;
        position: absolute;
        left:50%;
        top:0;
        margin-left: -126px;
      }
      &:after {
        content: '';
        background-image: url('img/grafic-rsc-2.svg');
        background-repeat: no-repeat;
        display: block;
        height: 33px;
        position: absolute;
        width: 177px;
        top: 90px;
        right: 38px;
      }
      &.b{
        &:after {
          content: '';
          background-image: url('img/line_malbaratament.svg');
          height: 40px;
          width: 1px;
          top: 115px;
          right: 50%;
        }
      }
      ul {
        list-style: none;
        margin: 0 auto;
        margin-top: 0;
        @include grid-row();
        li {
          display: inline-block;
          font-size: rem-calc(11);
          text-transform: uppercase;
          line-height: rem-calc(18);
          text-align: center;
          letter-spacing: 0.9px;
          font-weight: 700;
          padding: rem-calc(5);
          margin-top: 10px;
          //margin-bottom: 30px;
          width:30%;
          float: left;

          &:before {
            content: none;
            /*height: 60px;
            background-color: #979797;
            width: 1px;
            left:50%;
            top: -60px;
            position: absolute;*/
          }
          &:nth-child(2){
            width:39%;

          }
          &.active {
            background-color: $primary-color;
            color: $white;
            font-weight: 400;
          }
        }
      }
      @media #{$medium-up} {
        margin-bottom: rem-calc(35);
        margin-top: 0;
        border-top: none;
        padding-top: 0;
        width: 620px;
        &:before {
          content: '';
          background-image: url('img/grafic-rsc-1.svg');
          background-repeat: no-repeat;
          background-position: center top;
          display: block;
          height: 57px;
          width: 349px;
          background-size: 349px;
          position: relative;
          margin-left: -175px;
        }
        &:after {
          top: 110px;
          right: 135px;
        }
        ul {

          li {
            font-size: rem-calc(14);
            /*display: inline;
            font-size: rem-calc(14);
            padding: rem-calc(10);
            float: none;*/

          }
        }
      }
      @media #{$large-up} {
        width: 700px;
        margin-bottom: rem-calc(75);
        &:after {
          top: 120px;
          right: 175px;
        }
        ul {
          margin-top: rem-calc(15);
          li {
            display: inline;
            font-size: rem-calc(14);
            padding: rem-calc(10);
            float: none;
          }
        }
      }
    }

  }
  .destacat-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    //background-image: url('img/img-2-portada.jpg');
    margin-top: rem-calc(50);
    overflow: hidden;
    @media #{$large-up} {
      height: rem-calc(746);
    }
    &.b {
      margin-top: 0;
    }
    .text {
      background-color: rgba(0,0,0,.6);
      padding-bottom: rem-calc(30);
      @media #{$large-up} {
        height: rem-calc(746);
      }
      h2 {
        font-size: rem-calc(36);
        font-weight: 300;
        color: $white;
        line-height: 45px;
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(30);

        text-align: left;
        text-transform: none;
        @media #{$large-up} {
          font-size: rem-calc(56);
          margin-top: rem-calc(150);
          line-height: 60px;
          margin-right: rem-calc(50);
        }
      }
      p {
        color: $white;
        font-weight: 400;
        font-size: rem-calc(18);
        @media #{$medium-up} {
          font-size: rem-calc(20);
        }
      }
    }
    .text-segon {
      background-color: rgba(0,0,0,.6);
      height: rem-calc(300);
      padding-bottom: rem-calc(30);
      @media #{$medium-up} {

        height: rem-calc(400);
      }
      @media #{$large-up} {
        background-color: transparent;
        height: rem-calc(746);
      }
      h2 {
        font-size: rem-calc(36);
        font-weight: 300;
        color: $white;
        line-height: 45px;
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(30);

        text-align: left;
        text-transform: none;
        @media #{$large-up} {
          font-size: rem-calc(56);
          margin-top: rem-calc(150);
          line-height: 60px;
          margin-right: rem-calc(50);
        }
      }
      p {
        color: $white;
        font-weight: 400;
        font-size: rem-calc(18);
        @media #{$medium-up} {
          font-size: rem-calc(20);
        }
      }
      .info-destacat {
        @media #{$medium-up} {

        }
        @media #{$large-up} {
          position: absolute;
          left: rem-calc(620);
        }
      }
    }
    .primer {
      @media #{$medium-up} {
        padding-right: 3.125rem;
      }
      &:before {
        content: '';
        display: block;
        height: rem-calc(746);
        width: 1000%;
        position: absolute;
        top: 0;
        left: -1000%;
        background-color: rgba(0,0,0,.6);
      }
    }
    .segon {
      @media #{$medium-up} {
        padding-left: 3.125rem;
      }
      &:before {
        content: '';
        display: block;
        height: rem-calc(746);
        width: 1000%;
        position: absolute;
        top: 0;
        right: -1000%;
        background-color: rgba(0,0,0,.6);
      }
    }

  }
  .com {
    text-align: center;
    .icones {
      margin: rem-calc(50) 0 0;
      li {
        margin-bottom: rem-calc(50);
        padding:0 .5em;
        @media #{$medium-up} {
          margin-bottom: rem-calc(25);
          padding:0 1.25em;
        }
        &:before {
          content: none;
        }
        h3 {
          text-transform: uppercase;
          font-size: rem-calc(20);
          font-weight: 700;
          margin: rem-calc(16 0 0 0);
          @media #{$medium-up} {
            margin-bottom: rem-calc(10);
          }
        }
      }

    }
  }
  .alimentacio {
    text-align: center;
    padding: rem-calc(90) 0;
    h2{
      margin: 0;
      line-height: 1;
    }
    img {
      margin-bottom: rem-calc(40);
    }
  }
  .blau {
    padding-bottom: rem-calc(50);
    .icones {
        margin-top: rem-calc(-80);
        h3 {
          color: $white;
        }
          p {
            color: $white !important;
          }
      }
  }
  .bg {
    .icones {
      margin-bottom: rem-calc(50);
    }
  }
  .sabermes {
    padding: rem-calc(50 0 0);
    .videowrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: rem-calc(40);
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .logosaber {
      text-align: center;
      margin-top: rem-calc(60);
      li {
        display: inline-block;
        padding: rem-calc(0 30);
        &:before {
          content: none;
        }
        a {
          text-decoration: none;
          h4 {
            font-size: rem-calc(18);
          }
          &:hover {
            opacity: .6;
          }
        }
      }
    }
  }
  .links {
    text-align: center;
    padding: rem-calc(50 0 0);
    @media #{$medium-up} {
      height: rem-calc(236);
    }
    ul {
      margin: 0;
      li {
        display: block;
        margin: rem-calc(0 0 50);
        padding: 0;
        @media #{$medium-up} {
          display: inline-block;
          padding: 0 rem-calc(20);
          //width: 33%;
          vertical-align: top;
        }
        &:before {
          content: none;
        }
        a {
          text-decoration: none;
          @media #{$medium-up} {
            .imatge{
              height: 70px;
              width: 100%;
              position: relative;
              text-align: center;
              margin: 0 auto;
              img{
                position: absolute;
                bottom: 0;
                left:50%;
                transform: translateX(-50%);
              }
            }
          }
          h4 {
            text-transform: uppercase;
            font-size: rem-calc(16);
            letter-spacing: 1.3px;
            margin-top: rem-calc(15);
          }
          &:hover {
            opacity: .6;
          }
        }
      }
    }
    &.blau{
      a{
        color:#fff;
        h4 {
          color:#fff;
        }
      }
    }
  }
}
.top-img{
  background-size: cover;
  margin-top: 1.5rem;
  min-height: 400px;
  position: relative;
  h2{
    font-size: rem-calc(22);
    color: $body-font-color;
    margin: 0;
    font-weight: 300;
    text-align: left;

  }
  .overlay{
    background-color: #fff;
    background-color: rgba(#fff,.85);
    position: absolute;
    bottom: 0;
    padding: 1em;
  }
  .btn{
    margin-bottom: 0;
  }
}
