.btn {
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(1);
  padding: rem-calc(6) rem-calc(16);
  text-align: center;
  background-color: transparent;
  border-radius: 20px;
  letter-spacing: 1.4px;
  color: $white;
  background-color: $primary-color;
  font-size: rem-calc(16);
  line-height: 24px;
  text-decoration: none !important;
  margin-top: rem-calc(10);

  @media #{$medium-up} {
     padding: rem-calc(17);
     text-transform: uppercase;

     margin-top: rem-calc(10);
  }
  @media #{$large-up} {
     padding: rem-calc(37);
     margin-top: rem-calc(30);
     font-size: rem-calc(18);
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;


  }

}
