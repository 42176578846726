// mapa google
.map {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 0;
  }
}

// img dreta/esq
.img-right {
  img {
    @media #{$medium-up} {
      float: right;
      margin-left: vr(1);
    }
  }
}
.img-left {
  img {
    @media #{$medium-up} {
      float: left;
      padding-right: 1.25rem;
    }
  }
}
p.img-right {
    @media #{$medium-up} {
      float: left;
      margin-right: vr(1);
    }
}
.txtImgEsq img {
    float: left;
    padding-right: 1.25rem;
}

.back {
  display: inline-block;
  margin-bottom: vr(1);
  @media #{$medium-up} {
    position: absolute;
    right: $column-gutter*.5;
    top: vr(.5);
    margin-bottom: 0;
  }
  &:before {
    content: '\2190';
  }
}
//tipografia
h2 {
  font-weight: 700;
  color: $primary-color;
  text-transform: uppercase;
  font-size: rem-calc(30);
  text-align: center;
  margin-bottom: rem-calc(20);
  @media #{$medium-up} {
    font-size: rem-calc(36);
  }

}
p {
  font-size: rem-calc(16);
}
h1.heading {
    font-size: rem-calc(30);
    color: $primary-color;
    text-align: center;
    letter-spacing: 0px;
    margin-bottom: rem-calc(5);
    text-transform: uppercase;
    font-weight: 900;
    @media #{$medium-up} {
      font-size: rem-calc(70);
    }
    &.small {
      font-size: rem-calc(30);
      line-height: 1.15;
      margin-top: rem-calc(50);
      @media #{$medium-up} {
        font-size: rem-calc(47);
      }
      span {
        display: block;
      }
    }
  }

h2.heading {
  font-size: rem-calc(36);
  color: $primary-color;
  text-align: center;
  letter-spacing: 0px;
  margin-bottom: rem-calc(40);
  text-transform: uppercase;
  font-weight: 900;
  @media #{$medium-up} {
    font-size: rem-calc(47);
  }

}
.subtitol {
  text-align: center;
  max-width: rem-calc(500);
  font-size: rem-calc(18);
  font-weight: 400;
  margin: 0 auto;
}
.destacat {
  max-width: rem-calc(780);
  text-align: center;
  margin: 0 auto;
  font-size: rem-calc(25);
  font-weight: 300;
  line-height: 32px;
}
.destacat-gran {
  font-size: rem-calc(36);
  font-weight: 300;
  line-height: 46px;
  margin: rem-calc(30 0);
  text-align: center;
}
.degradat {
  height: 70px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0,0.12));
}
.faq {
  counter-reset: my-faq-counter;
}
.faq dt {
  position: relative;
  padding: 4px 0 10px 0;
  font-size: rem-calc(20);
  line-height: 1.3;
  @media #{$medium-up} {
    font-size: rem-calc(25);
  }
}
.faq dt:before {
  content: counter(my-faq-counter);
  counter-increment: my-faq-counter;
  position: absolute;
  left: 0;
  top: 0;
  font-size: rem-calc(30);
  color: $primary-color;
  border: solid 4px $primary-color;
  width: 43px;
  height: 43px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  @media #{$medium-up} {
    width: 63px;
    height: 63px;
    line-height: 55px;
    font-size: rem-calc(40);
  }
}
.faq dd {
  margin: 0 0 rem-calc(40) 0;
  font-weight: 300;
  font-size: rem-calc(18);
  letter-spacing: 0;
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }

}
.faq dt, .faq dd {
  padding-left:rem-calc(50);
  @media #{$medium-up} {
    padding-left:rem-calc(80);
  }
}
.alignLeft {
  text-align: left;
  max-width: rem-calc(620);
  margin: 0 auto;
}
// imatge amb text overlay
.img-txt {
  @media #{$medium-up} {
    position: relative;
    margin-bottom: vr(2);
    &--last {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 0;
    }
  }


}
.bg {
  background-color: #f4f1e6;
  padding: rem-calc(50 0);
}
.blau {
  background-color: $secondary-color;
}
.txt-overlay {
  @media #{$medium-up} {
    padding: vr(2) 0;
    background-color: #fff;
    background-color: rgba(#fff, .7);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .info-block {
      margin-bottom: 0;
    }
  }
}


// imatge intro top
.intro-img {
  margin-bottom: 0;
  padding-bottom: vr(.5);
  @media #{$medium-up} {
    padding-bottom: vr(1.5);
  }
  @media #{$large-up} {
    padding-bottom: vr(2);
  }
  .escola &, .interior & {
    padding-bottom: 0;
  }
}
.u-full {
  width: 100%;
}
figure {
  margin: 0;
}
.fulleto {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: block;
  margin-left: auto;
  margin-right: auto;
  @media #{$medium-up} {
    margin-top: vr(-3);
    margin-right: vr(1);
    display: inline-block;
  }
}

.more {
  margin-top: vr(1.5);
}
.column+.column:last-child, .column+.columns:last-child, .columns+.column:last-child, .columns+.columns:last-child {
  float: left;
}
.popup-youtube {
  img {
    margin: 0 auto;
    width: 70px;
  }
}
.dl {
  border-bottom: none;
  background-color: #f7f7eb;
  padding: .75rem .75rem;
  &:before {
      top: 10px;
      //@include sprite('ico-down');
    }
    a {
      margin-left: 35px;
    }
    table & {
      background-color: transparent;
    }
}

h5 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: rem-calc(15);
}
.duesCol {
  @media #{$medium-up} {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
table {
    overflow: auto;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%!important;
    display: block;
    @media #{$medium-up} {
      display: table;
    }
}

:target:before {
  content: " ";
  display: block;
  height: 120px;
  margin-top: -120px;
  visibility: hidden;
}
.offset:before {
  content: " ";
  display: block;
  height: 120px;
  margin-top: -120px;
  visibility: hidden;
}
.error404 {
  .main-content {
    background-image: linear-gradient(to top, #555555, #000000);
    .esimar-404 {
      margin: 0 auto;
      margin-top: rem-calc(75);
      display: block;
    }
    h1 {
      font-size: rem-calc(100);
      font-weight: 100;
      line-height: 1;
      letter-spacing: 22.22221183776855px;
      color: #fff;
      margin-top: rem-calc(35);
      @media #{$medium-up} {
         font-size: rem-calc(160);
      }
    }
    h2 {
      p {
        font-size: rem-calc(23);
        color: #fff;
        text-align: center;
        font-family: lato_lightregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
        @media #{$medium-up} {
          font-size: rem-calc(28);
        }
      }
    }
    p {
      font-size: rem-calc(16);
      color: #fff;
      line-height: 1.5;
      text-align: center;
    }
    .error {
      margin-top: rem-calc(50);
      margin-bottom: rem-calc(50);
      text-align: center;
      li {
        display: block;
        padding-left: 0;
        @media #{$medium-up} {
            display: inline-block;
          }
        &:before {
          content: none;
        }
        .btn {
          color: #fff;
          min-width: auto;
          @media #{$medium-up} {
            min-width: rem-calc(260);
          }
        }
        &.last {
          @media #{$large-up} {
            padding-left: rem-calc(25);
          }
        }
      }
    }
  }
}
.miss-ok {
  h1 {
    font-family: 'lato';
    font-size: rem-calc(26);
    letter-spacing: 2.888888597488403px;
    margin-bottom: rem-calc(10);
  }
  p {
    text-align: center;
    font-size: rem-calc(24);
  }
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: rem-calc(30);
  }
}
.miss-error {
  h1, h2 {
    font-family: 'lato';
    font-size: rem-calc(26);
    letter-spacing: 2.888888597488403px;
    margin-bottom: rem-calc(10);
    color: #e3185f;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    text-align: center;
    font-size: rem-calc(24);
    &.btn {
      font-size: rem-calc(14);
        display: block;
        margin: 0 auto;
        max-width: rem-calc(300);
        margin-top: rem-calc(40);
        background-color: #fff;
        color: #006eb8;
        a {
          color: #006eb8;
        }
      &:hover {
        background-color: #006eb8;
        a {
          color: #fff;
        }
      }
    }
  }
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: rem-calc(30);
  }
}
.list-h-boxes {
    @media #{$medium-up} {
      @include block-grid(4);
    }
    li {
      padding-left: 0;
      &:before {
        content: none;
      }
    }
  a {
    font-size: rem-calc(24);
    line-height: 1.2;
    display: block;
    color: #fff;
    padding: vr(1);
    min-height: vr(8);
    margin-bottom: vr(1);
      @media #{$medium-up} {
        font-size: rem-calc(20);
        margin-bottom: 0;
      }
    &:hover {
      text-decoration: none;
    }
    &:before {
      content: '';
      display: block !important;
      margin-bottom: vr(.5);
      font-size: 1rem;
    }
    @media #{$large-up} {
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        background-color: gray;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    img {
      display: block;
      margin-bottom: rem-calc(10);
    }
  }

}
.show-small {
  display: block;
  @media #{$medium-up} {
    display: none !important;
  }
}
.formulari-contacta {
  text-align: center;
  margin-bottom: rem-calc(75);
  p{
    font-size: rem-calc(20);
  }
}
form.houdiniForm {
  margin-top: rem-calc(50);
  fieldset{
    border: none;
    legend{
      color: $primary-color;
      text-transform: uppercase;
      font-size: rem-calc(32);
      text-align: center;
    }
    input, textarea {
      height: 50px;
      border-radius: 6px;
      background-color: #ffffff;
      border: none;
      margin: rem-calc(34) 0 0;
      font-size: rem-calc(15);
      text-transform: uppercase;
      padding-left: rem-calc(15);
      font-weight: 300;
    }
    textarea{
      min-height: 200px;
    }
    label {
        display: none;
    }
    .columns {
      padding: 0;
    }

    .checkbox-unit{
      margin: rem-calc(25 0 10);
      padding-left: 3px;
      label{
        position: relative;
        padding-left: rem-calc(16);

        display: block;
      }
      input {
        position: absolute;
        top: 5px;
        left: 0;
        margin: 0;
        height: auto;
        background-color: #fff;
        //transform: scale(1.5);
      }
    }
    .checkbox-group{
      margin-top: rem-calc(34);
      label{
        position: relative;
        padding-left: rem-calc(20);
        margin-bottom: rem-calc(34);
      }
      input {
        position: absolute;
        top: 5px;
        left: 0;
        margin: 0;
        height: auto;
        background-color: #fff;
        //transform: scale(1.5);
      }
    }
  }
}