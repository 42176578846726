.main-header {
  background-color: $primary-color;
  @media #{$medium-up} {
    height: rem-calc(119);
  }
  .site-title, h1 {
    margin: rem-calc(0 0 21);
    line-height: 1;
    @media #{$medium-up} {
      margin: rem-calc(19 0 21);
    }

    a:hover {
      border: 0;
    }

    img {
      width: 220px;

      @media #{$large-up} {
        width: 220px;
      }
    }
  }

  .lang-list {
    display: none;
  }
  .menu-lang-mobile {

  list-style: none;
  width: 100%;
  @include block-grid(2);
  margin: rem-calc(50) 0 0;
  background-color: #1F8FBB;

  li {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    font-size: rem-calc(14);
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 18px;
      background-color: #fff;
      vertical-align: middle;
      margin-left: vr(.5);
      margin-top: -3px;
      top: 50%;
      margin-top:-9px;
      right: 0;
    }
    &.last {
      &:after {
        display: none;
      }
    }
  }
  a {
    color: #fff;
  }
}
  @media #{$medium-up} {
    .menu-lang-mobile {
      display: none;
    }
    .lang-list {
      display: inline-block;
      float: right;
      li {
        display: inline;
        color: #fff;
        position: relative;
        &.active a {
          color: #000;
          &:hover {
            text-decoration: none;
            cursor: default;
            abbr {
              cursor: default;
            }
          }
        }
        &:after {
          content: '';
          height: 16px;
          width: 1px;
          background-color: #fff;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -8px;
        }
        &:last-child:after{
          content: none;
        }
      }

      a {
        display: inline-block;
        padding: 0 0.5em;
        font-size: rem-calc(16);
        color: #fff;
        &:hover {
          border: 0;
          text-decoration: underline;
          abbr {
            cursor: pointer;
          }
        }
      }
    }

    .share {
      display: inline-block;
      margin: 0 0 0 6px;
      vertical-align: top;
      list-style: none;

      li {
        float: left;
      }

      a {
        margin: 0 0 0 12px;
        display: inline-block;
      }
    }
  }
}

.menu-trigger {
  position: absolute;
  top: 29px;
  right: $column-gutter/2;
  z-index: 20;
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(12);

  /*&:before {
    @extend .icon-menu-small;
    content: '';
    display: block;
    margin-bottom: 3px;
  }*/

  @media #{$medium-up} {
    display: none;
  }
}
